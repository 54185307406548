import React from 'react'
import AppStore from 'src/images/svg/appstore.svg'
import GooglePlay from 'src/images/svg/googleplay.svg'


const storeButtons = () => (
  <div className="mt-10 flex justify-center space-x-4 md:mt-10 md:justify-end">
      <a
        href='https://apps.apple.com/pl/app/rosa-rules-of-sport-app/id1622562179'
        target="_blank">
          <img
            src={AppStore}
            alt="ROSA App Store"
          />
      </a>
      <a
        href='https://play.google.com/store/apps/details?id=pl.mamf.rosa.example&hl=pl&gl=US'
        target="_blank"
      >
          <img
            src={GooglePlay}
            alt="ROSA Google Play"
          />
      </a>
  </div>
)


export default storeButtons