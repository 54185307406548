// i18next-extract-mark-ns-start gui

import React, { FC } from 'react'
import Button from 'src/components/ui/Button'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

export interface IFramedIconProps {
  image: {
    url: string
    alt: string
  }
  firstPartOfTitle: string
  secondPartOfTitle: string
  link?: string
  className?: string
}

const FramedIcon: FC<IFramedIconProps> = ({
  image,
  firstPartOfTitle,
  secondPartOfTitle,
  link,
  className,
}) => {
  const { t } = useTranslation('gui')
  return (
    <div
      className={`text-center p-4 border-2 border-mediumLightGray rounded-xl flex flex-col md:flex-row  justify-start md:justify-between  md:items-center ${className}`}
    >
      <div className="flex-1 flex justify-evenly md:justify-start gap-x-4 md:gap-x-0">
        <img
          src={image.url}
          alt={image.alt}
          className="h-15"
          style={{ width: '129px', height: '64px' }}
        />
        <div className="hidden md:block md:flex-1 " />
        <div className="flex flex-col justify-start md:justify-center ">
          <h4 className="font-palanquinDark text-lg  md:whitespace-nowrap">
            {firstPartOfTitle}
          </h4>
          <h4 className="font-palanquinDark text-lg font-semibold text-red">
            {secondPartOfTitle}
          </h4>
        </div>
        <div className="md:flex-1 hidden md:block" />
      </div>
      {link ? (
        <>
          <div className="bg-mediumLightGray w-full h-0.5 mt-3 md:hidden" />
          <Link to={link} target="_blank" rel="noreferrer">
            <Button className="font-palanquin font-thin whitespace-nowrap mt-4 md:mt-0 w-full">
              {t('see the project')}
            </Button>
          </Link>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

FramedIcon.defaultProps = {
  className: '',
}

export default FramedIcon
