import React, { FC } from 'react'
import FramedIcon, { IFramedIconProps } from 'src/components/ui/FramedIcon'
import Subtitle from 'src/components/ui/Subtitle'
import H2 from 'src/components/ui/title/H2'
import styled from 'styled-components'
import tw from 'twin.macro'

export interface IFramedIconsInRowProps {
  translationContent: {
    subtitle: string
    icons: IFramedIconProps[]
    firstPartOfTitle: string
    secondPartOfTitle: string
    sectionName: string
  }
}

const GapBox = styled.div`
  & > *:not(:last-child) {
    ${tw`mb-8`}
  }
`

const FramedIconsInRow: FC<IFramedIconsInRowProps> = ({
  translationContent: {
    subtitle,
    icons,
    firstPartOfTitle,
    secondPartOfTitle,
    sectionName,
  },
}) => (
  <section
    data-name={sectionName}
    id={sectionName}
    className="container py-10 md:py-20"
  >
    <div className="lg:flex justify-between">
      <div className="lg:w-5/12 2xl:w-5/12">
        <Subtitle className="mb-2 md:mb-6">{subtitle}</Subtitle>
        <H2 firstPart={firstPartOfTitle} secondPart={secondPartOfTitle} />
      </div>
      <div className="lg:w-7/12 2xl:w-7/12">
        <GapBox className="flex flex-col mt-14 md:mt-24 lg:mt-0">
          {icons.map((icon) => (
            <FramedIcon
              image={icon.image}
              firstPartOfTitle={icon.firstPartOfTitle}
              secondPartOfTitle={icon.secondPartOfTitle}
              link={icon.link}
              className="flex-1"
            />
          ))}
        </GapBox>
      </div>
    </div>
  </section>
)

export default FramedIconsInRow
