import { Link } from 'gatsby'

import React, { FC, useRef } from 'react'
import Button from 'src/components/ui/Button'
import Subtitle from 'src/components/ui/Subtitle'
import H2 from 'src/components/ui/title/H2'
import { useRosaSectionAnimations } from './animations'
import StoreButtons from 'src/components/ui/StoreButtons'

interface IRosaSectionProps {
  className?: string
  translationContent: {
    subtitle: string
    firstPartOfTitle: string
    secondPartOfTitle: string
    sectionName: string
    ctaLink: string
    ctaText: string
    image: {
      url: string
      alt: string
    }
  }
}

const RosaSection: FC<IRosaSectionProps> = ({
  className,
  translationContent,
}) => {
  const subtitleRef = useRef<HTMLDivElement>(null)
  const largeTitleRef = useRef<HTMLDivElement>(null)

  useRosaSectionAnimations(subtitleRef, largeTitleRef)

  return (
    <div
      data-name={translationContent.sectionName}
      id={translationContent.sectionName}
      className={`${className} container py-10 md:py-20`}
    >
      <div className="">
        <div className="">
          <Subtitle
            ref={subtitleRef}
            className="mb-2 md:mb-6 opacity-0 text-right"
          >
            {translationContent.subtitle}
          </Subtitle>
          <H2
            className="opacity-0 text-right"
            ref={largeTitleRef}
            firstPart={translationContent.firstPartOfTitle}
            secondPart={translationContent.secondPartOfTitle}
          />
          <div className="text-center md:text-right mt-12">
            <Link to={translationContent.ctaLink}>
              <Button>{translationContent.ctaText}</Button>
            </Link>
          </div>
        </div>
        <div className="mt-12 md:-mt-12 md:mr-80">
          <img
            src={translationContent.image.url}
            alt={translationContent.image.alt}
          />
        </div>
        <StoreButtons></StoreButtons>
      </div>
    </div>
  )
}

RosaSection.defaultProps = {
  className: '',
}

export default RosaSection
