import React, { FC } from 'react'
import RedBulletList from 'src/components/ui/RedBulletList'

interface IBulletListProps {
  className?: string
  items: string[]
}

const StyledBulletList: FC<IBulletListProps> = ({ className, items }) => (
  <RedBulletList
    className={`list-square list-outside space-y-5 text-lg font-palanquinDark tracking-tight ${className}`}
  >
    {items.map((item) => (
      <li>{item}</li>
    ))}
  </RedBulletList>
)

StyledBulletList.defaultProps = {
  className: '',
}

export default StyledBulletList
