import * as React from 'react'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import Hero from 'src/components/sections/hero'
import BasicTeaser from 'src/components/sections/teaser/basic-teaser'
import FramedIconsInRow from 'src/components/sections/FramedIconsInRow'
import StyledBulletList from 'src/components/ui/StyledBulletList'
import TeaserWithHighlight from 'src/components/sections/teaser/TeaserWithHighlight'
import ContactSection from 'src/components/sections/ContactSection'
import Highlight from 'src/components/sections/teaser/Highlight'
import { Link } from 'gatsby-plugin-react-i18next'
import Button from 'src/components/ui/Button'
import RosaSection from 'src/components/sections/rosa'

const IndexPage = ({ data }) => {
  const translation = data.directus.pages[0].translations[0]
  if (!translation) {
    return <h1>Missing translation</h1>
  }
  const cmsJson = JSON.parse(translation.content)
  return (
    <Layout title={cmsJson.title} metas={cmsJson.metas}>
      <Hero translationContent={cmsJson.mainSection} showBadges />
      <BasicTeaser translationContent={cmsJson.firstSection} isImageSnapped>
        <Highlight>
          <p className="font-palanquinDark font-semibold">
            {cmsJson.firstSection.content}
          </p>
          <p className="text-sm md:text-base font-semibold mt-8">
            {cmsJson.firstSection.subContent}
          </p>
        </Highlight>
      </BasicTeaser>
      <BasicTeaser isReversed translationContent={cmsJson.goalsSection}>
        <Highlight isReversed className="mt-16 pl-8">
          <StyledBulletList
            items={cmsJson.goalsSection.list}
            className="text-left"
          />
        </Highlight>
        <Link to={cmsJson.goalsSection.ctaLink}>
          <Button className="mt-12">{cmsJson.goalsSection.ctaText}</Button>
        </Link>
      </BasicTeaser>
      <TeaserWithHighlight
        isImageSnapped
        translationContent={cmsJson.solutionsSection}
      />
      <FramedIconsInRow translationContent={cmsJson.expertiseSection} />
      <RosaSection translationContent={cmsJson.rosaSection} />
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      pages(filter: { slug: { _eq: "/sport" } }) {
        translations(filter: { languages_code: { code: { _eq: $language } } }) {
          content
        }
      }
    }
  }
`

export default IndexPage
